<template>
  <div class="pgoffice-page">
    <div id="pg_tool" class="pg-tool">
      文档主题：
      <input
        name="FileSubject"
        class="pg-input"
        id="FileSubject"
        type="text"
        style="width: 180px"
        v-model="WordName"
      />
      <button class="pg-button" @click="Save">{{ "保存并关闭" }}</button>
      <button class="pg-button" @click="Close">{{ "取消" }}</button>
    </div>
    <div id="pageOfficeContent" v-html="html_code" class="office-main"></div>
  </div>
</template>

<script>
import { CreateExcel } from "@/api/pageOffice";

export default {
  name: "pageOffice_word",
  data: function () {
    return {
      html_code: "",
      params: "",
      WordName: "",
    };
  },
  methods: {
    Save: function () {
      if (!this.WordName) {
        alert("请输入文档主题");
        return;
      }
      document.getElementById("PageOfficeCtrl1").WebSave();
      var PageOfficeValue =
        document.getElementById("PageOfficeCtrl1").CustomSaveResult;
      PageOfficeValue += "";
      window.external.CallParentFunc(
        "PageOfficeCbFn('" + encodeURI(PageOfficeValue) + "')"
      );
      window.external.close();
    },
    Close: function () {
      window.external.CallParentFunc("PageOfficeCloseFn()");
      window.external.close();
    },
    init: function () {
      CreateExcel().then((res) => {
        this.html_code = res;
      });
    },
  },
  created: function () {
    window.BeforeDocumentSaved = function (e) {
    };
    window.Save = this.Save;
    window.Close = this.Close;
    this.init();
  },
};
</script>
